import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react';
import { useContext, useState } from 'react';

import ActiveLink from '@/components/ui/links/active-link';
import Button from '@/components/ui/button';
import { Check } from '../icons/check';
import { ChevronForward } from '@/components/icons/chevron-forward';
import { Copy } from '../icons/copy';
import { Menu } from '@/components/ui/menu';
import { PowerIcon } from '@/components/icons/power';
import { Transition } from '@/components/ui/transition';
import { WalletContext } from '@/lib/hooks/use-connect';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from '@/lib/hooks/use-copy-to-clipboard';
import { useModal } from '@/components/modal-views/context';

export default function WalletConnect() {
  const {
    address,
    balance,
    wallet,
    connect,
    disconnect,
    setAddress,
    userArray,
    FakeBUSDcontract,
    add,
    setLoading1,
    loading1,
    setAdd,
    testEthContract,
  } = useContext(WalletContext);
  let [copyButtonStatus, setCopyButtonStatus] = useState(false);
  let [loader, setLoader] = useState(false);

  let [_, copyToClipboard] = useCopyToClipboard();
  const handleCopyToClipboard = () => {
    copyToClipboard(address);
    setCopyButtonStatus(true);
    setTimeout(() => {
      setCopyButtonStatus(copyButtonStatus);
    }, 2500);
  };
  const notify = (message: any) => toast.success(message);

  return (
    <>
      {address ? (
        <div className="flex items-center gap-3 sm:gap-6 lg:gap-8">
          <div className="relative">
            <Menu>
              <Menu.Button className="block h-10 w-10 overflow-hidden rounded-full border-3 border-solid border-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 shadow-main transition-all hover:-translate-y-0.5 hover:shadow-large dark:border-gray-700 sm:h-12 sm:w-12"></Menu.Button>
              <Transition
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-4"
              >
                <Menu.Items className="absolute right-0.5 z-30 mt-3 w-72 origin-top-right rounded-lg bg-white shadow-large dark:bg-gray-900 sm:right-2">
                  <Menu.Item>
                    <div className="border-b border-dashed border-gray-200 p-3 dark:border-gray-700">
                      <ActiveLink
                        href="/profile"
                        className="flex items-center gap-3 rounded-lg py-2.5 px-3 text-sm font-medium text-gray-900 transition hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800"
                      >
                        <span className="h-8 w-8 rounded-full border-2 border-solid border-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:border-gray-700"></span>
                        <span className="grow uppercase">
                          View Your Profile
                        </span>
                        <ChevronForward />
                      </ActiveLink>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <Menu.Item>
                      <div className="border-b border-dashed border-gray-200 px-6 py-5 dark:border-gray-700">
                        <div className="flex items-center justify-between gap-3">
                          <span className="mt-0 text-sm font-medium -tracking-tighter text-gray-600 dark:text-gray-400 xl:mt-2">
                            Address:
                          </span>
                          {/* <span className="rounded-lg bg-gray-100 px-2 py-1 text-sm tracking-tighter dark:bg-gray-800">
                            {address.slice(0, 6)}
                            {'...'}
                            {address.slice(address.length - 6)}
                          </span> */}
                          <div className="mt-0 inline-flex h-9 items-center rounded-full bg-gray-100 shadow-card dark:bg-light-dark xl:mt-2">
                            <div className="text w-28 grow-0 truncate text-ellipsis bg-center text-xs text-gray-800 ltr:pl-4 rtl:pr-4 dark:text-gray-300 sm:w-32 sm:text-sm">
                              {`${address.slice(0, 5)}...${address.slice(
                                38,
                                42
                              )}`}
                            </div>
                            <div
                              className="flex cursor-pointer items-center px-4 text-gray-500 transition hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
                              title="Copy Address"
                              onClick={handleCopyToClipboard}
                            >
                              {copyButtonStatus ? (
                                <Check className="text-green-500 h-auto w-3.5" />
                              ) : (
                                <Copy className="h-auto w-3.5" />
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="mt-3 flex items-center justify-between gap-3">
                            <span className="text-sm font-medium -tracking-tighter text-gray-600 dark:text-gray-400">
                              USDT :
                            </span>
                            <span className="rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium tracking-wide text-gray-800 dark:bg-gray-800 dark:text-gray-200">
                              {/* {userArray.BusdToken.toFixed(3)} */}
                              {(
                                Math.round(userArray.BusdToken * 1000) / 1000
                              ).toFixed(3)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Menu.Item>
                  </Menu.Item>
                  <Menu.Item>
                    <div className="p-3">
                      <div
                        className="flex cursor-pointer items-center gap-3 rounded-lg py-2.5 px-3 text-sm font-medium text-gray-900 transition hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800"
                        onClick={() => {
                          if (wallet) {
                            disconnect(wallet);
                            setAddress('');
                            setAdd('');
                          }
                        }}
                      >
                        <PowerIcon />
                        <span className="grow ">Disconnect</span>
                      </div>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          {/* <Button
            className="shadow-main hover:shadow-large"
            onClick={() => {
              if (wallet) {
                disconnect(wallet);
                setAddress('');
                setAdd('');
              }
            }}
          >
            Disconnect
          </Button> */}
        </div>
      ) : (
        <Button
          onClick={() => connect()}
          className="shadow-main hover:shadow-large"
        >
          Connect
        </Button>
      )}
    </>
  );
}
