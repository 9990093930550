import { useContext, useState } from 'react';
import { SearchIcon } from '@/components/icons/search';
import { NFTContext } from '@/lib/hooks/NFT-marketPlace';
import axios from 'axios';
import { WalletContext } from '@/lib/hooks/use-connect';
const MarketplaceDatabase: string | undefined =
  process.env.NEXT_PUBLIC_MARKETPLACE_DATABASE || '';
export const CategoryList = [
  {
    name: 'Art',
    value: 'Art',
  },
  {
    name: 'Collectibles',
    value: 'Collectibles',
  },
  {
    name: 'Cepheus Astronaut',
    value: 'astrolist',
  },
  {
    name: 'Cepheus Star',
    value: 'Cepheus_star',
  },
  {
    name: 'Music',
    value: 'Music',
  },
  {
    name: 'Photography',
    value: 'Photography',
  },
  {
    name: 'Sports',
    value: 'Sports',
  },
  {
    name: 'Utility',
    value: 'Utility',
  },
  {
    name: 'Virtual Worlds',
    value: 'Virtual_Worlds',
  },
  {
    name: 'Nature',
    value: 'Nature',
  },
  {
    name: 'Places',
    value: 'Places',
  },
  {
    name: 'Animals',
    value: 'Animals',
  },
  {
    name: 'Memes',
    value: 'Memes',
  },
  {
    name: 'other',
    value: 'other',
  },
];
interface CategorySelectTypes {
  onSelect: (value: string) => void;
}
export default function CategorySelect({ onSelect }: CategorySelectTypes) {
  const { NFTType, setNFTType } = useContext(NFTContext);
  let [searchKeyword, setSearchKeyword] = useState('');
  let CategoryListData = CategoryList;
  if (searchKeyword.length > 0) {
    CategoryListData = CategoryList.filter(function (item) {
      const name = item.name;
      return (
        name.match(searchKeyword) ||
        (name.toLowerCase().match(searchKeyword) && name)
      );
    });
  }
  async function handleSelectedCoin(value: string) {
    onSelect(value);
    setNFTType(value);
  }
  return (
    <div className="w-full rounded-lg bg-white text-sm shadow-large dark:bg-light-dark">
      <div className="relative">
        <SearchIcon className="absolute left-6 h-full text-gray-700 dark:text-white" />
        <input
          type="search"
          autoFocus={true}
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder="Search..."
          className="w-full border-x-0 border-b border-dashed border-gray-200 py-3.5 pl-14 pr-6 text-sm focus:border-gray-300 focus:ring-0 dark:border-gray-600 dark:bg-light-dark dark:text-white dark:focus:border-gray-500"
        />
      </div>
      <ul role="listbox" className="py-3">
        {CategoryListData.length > 0 ? (
          CategoryListData.map((item, index) => (
            <li
              key={index}
              role="listitem"
              tabIndex={index}
              onClick={() => handleSelectedCoin(item.value)}
              className="mb-1 flex cursor-pointer items-center gap-3 py-1.5 px-6 outline-none hover:bg-gray-100 focus:bg-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-600"
            >
              {/* <Avatar image={item.icon} size="xs" alt={item.name} /> */}
              <span className="text-sm tracking-tight text-gray-600 dark:text-white">
                {item.name}
              </span>
            </li>
          ))
        ) : (
          // FIXME: need coin not found svg from designer
          <li className="px-6 py-5 text-center">
            <h3 className="mb-2 text-sm text-gray-600 dark:text-white">
              Ops! not found
            </h3>
          </li>
        )}
      </ul>
    </div>
  );
}
