export function RightAlign(props: React.SVGAttributes<{}>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M17.9688 2.34375C18.5904 2.34375 19.1865 2.59068 19.626 3.03022C20.0656 3.46976 20.3125 4.0659 20.3125 4.6875V9.375C20.3125 9.9966 20.0656 10.5927 19.626 11.0323C19.1865 11.4718 18.5904 11.7188 17.9688 11.7188H9.375C8.7534 11.7188 8.15726 11.4718 7.71772 11.0323C7.27818 10.5927 7.03125 9.9966 7.03125 9.375V4.6875C7.03125 4.0659 7.27818 3.46976 7.71772 3.03022C8.15726 2.59068 8.7534 2.34375 9.375 2.34375H17.9688ZM8.59375 4.6875V9.375C8.59375 9.5822 8.67606 9.78091 8.82257 9.92743C8.96908 10.0739 9.1678 10.1562 9.375 10.1562H17.9688C18.176 10.1562 18.3747 10.0739 18.5212 9.92743C18.6677 9.78091 18.75 9.5822 18.75 9.375V4.6875C18.75 4.4803 18.6677 4.28159 18.5212 4.13507C18.3747 3.98856 18.176 3.90625 17.9688 3.90625H9.375C9.1678 3.90625 8.96908 3.98856 8.82257 4.13507C8.67606 4.28159 8.59375 4.4803 8.59375 4.6875Z"
        fill="currentColor"
      />
      <path
        d="M3.125 13.2812H17.9688C18.5904 13.2813 19.1865 13.5282 19.626 13.9677C20.0656 14.4073 20.3125 15.0034 20.3125 15.625V20.3125C20.3125 20.9341 20.0656 21.5302 19.626 21.9698C19.1865 22.4093 18.5904 22.6562 17.9688 22.6562H3.125C2.5034 22.6562 1.90726 22.4093 1.46772 21.9698C1.02818 21.5302 0.78125 20.9341 0.78125 20.3125V15.625C0.78125 15.0034 1.02818 14.4073 1.46772 13.9677C1.90726 13.5282 2.5034 13.2813 3.125 13.2812ZM2.34375 20.3125C2.34375 20.5197 2.42606 20.7184 2.57257 20.8649C2.71909 21.0114 2.9178 21.0938 3.125 21.0938H17.9688C18.176 21.0938 18.3747 21.0114 18.5212 20.8649C18.6677 20.7184 18.75 20.5197 18.75 20.3125V15.625C18.75 15.4178 18.6677 15.2191 18.5212 15.0726C18.3747 14.9261 18.176 14.8438 17.9688 14.8438H3.125C2.9178 14.8438 2.71909 14.9261 2.57257 15.0726C2.42606 15.2191 2.34375 15.4178 2.34375 15.625V20.3125Z"
        fill="currentColor"
      />
      <path
        d="M23.4375 23.4375C23.4375 23.6447 23.3552 23.8434 23.2087 23.9899C23.0622 24.1364 22.8635 24.2188 22.6562 24.2188C22.449 24.2188 22.2503 24.1364 22.1038 23.9899C21.9573 23.8434 21.875 23.6447 21.875 23.4375V1.5625C21.875 1.3553 21.9573 1.15659 22.1038 1.01007C22.2503 0.86356 22.449 0.78125 22.6562 0.78125C22.8635 0.78125 23.0622 0.86356 23.2087 1.01007C23.3552 1.15659 23.4375 1.3553 23.4375 1.5625V23.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
