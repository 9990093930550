import { ethers } from 'ethers';

const FakeBUSDaddress: string | undefined =
  process.env.NEXT_PUBLIC_FAKEBUSD_ADDRESS || '';
const Cepheusaddress: string | undefined =
  process.env.NEXT_PUBLIC_CEPHEUSADDRESS || '';
const Nodeaddress: string | undefined =
  process.env.NEXT_PUBLIC_NODEADDRESS || '';
const Stakeaddress: string | undefined =
  process.env.NEXT_PUBLIC_STAKEADDRESS || '';
const RewardAddress: string | undefined =
  process.env.NEXT_PUBLIC_REWARDADDRESS || '';
const RigelStakeContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_RIGELSTAKE_CONTRACTADDRESS || '';
const SiriusStakeContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_SIRIUSSTAKE_CONTRACTADDRESS || '';
const VegaStakeContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_VEGASTAKE_CONTRACTADDRESS || '';
const GetAllDataContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_GETALLDATA_CONTRACTADDRESS || '';
const TokenContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_TOKEN_CONTRACTADDRESS || '';

export const GeneralNodeData = async (
  con4: any,
  con3: any,
  con7: any,
  con9: any,
  setGeneralArray: any,
  Stakeaddress: any,

  setUserArray: any
) => {
  const price = await con9.getPriceOfNFTs();
  const contractPrice = await con7.getDeploymentPrice('6');
  // Tax for buy back

  // Rigel Global Claimed

  // Vega sold

  // ***************************
  setGeneralArray((prevGeneralArray: any) => ({
    ...prevGeneralArray,

    RigelPrice: parseInt(ethers.utils.formatUnits(price[0], '6')),
    SiriusPrice: parseInt(ethers.utils.formatUnits(price[1], '6')),
    VegaPrice: parseInt(ethers.utils.formatUnits(price[2], '6')),
    simpleContractPrice: contractPrice[0],
    taxableContractPrice: contractPrice[1],
    deflationaryContractPrice: parseInt(
      ethers.utils.formatUnits(contractPrice[2], '6')
    ),
    refletionaryContractPrice: parseInt(
      ethers.utils.formatUnits(contractPrice[3], '6')
    ),
    Erc721ContractPrice: contractPrice[4],
    Erc1155ContractPrice: contractPrice[5],
  }));
};

// export const AvailableReward = async (con2: any, setGeneralArray: any) => {
//   const series = await con2.balanceOf(RewardAddress);
//   const value = parseInt(ethers.utils.formatEther(series._hex));
//   const value1 = value / 1000000;
//   const twoPlaces = value1.toFixed(2);
//   setGeneralArray((prevGeneralArray: any) => ({
//     ...prevGeneralArray,
//     AvailableReward: twoPlaces,
//     AvailableRewardFull: value,
//   }));
// };
