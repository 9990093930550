// eslint-disable-next-line react-hooks/exhaustive-deps

import {
  CepheusABI,
  ContractGeneralABI,
  FakeBUSDABI,
  GetAllDataABI,
  NodeABI,
  PresaleABI,
  RigelStakingABI,
  SiriusStakingABI,
  StakeABI,
  TokenContractABI,
  VegaStakingABI,
} from '../constants/abi';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Wallet, utils } from 'ethers';
import { cepheusStarABI, testEhABI } from '../constants/cepheusabi';
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react';

import { Busd } from './use-user-connected';
import { Web3Provider as EthersWeb3Provider } from '@ethersproject/providers';
import { GeneralNodeData } from './use-whenUserNotConnected';
import Logo from '@/components/ui/menu-icon';
import { astroNFTABI } from '@/lib/constants/AstroNFT';
import axios from 'axios';
import cepheus from '@/assets/images/CEP-Logo-main-black.png';
import { ethers } from 'ethers';
import fortmaticModule from '@web3-onboard/fortmatic';
import { getUserDataWhenWalletConnected } from './use-user-connected';
import { init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import keepkeyModule from '@web3-onboard/keepkey';
import ledgerModule from '@web3-onboard/ledger';
import portisModule from '@web3-onboard/portis';
import torusModule from '@web3-onboard/torus';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import walletLinkModule from '@web3-onboard/walletlink';

// import Web3Modal from 'web3modal';

// const web3modalStorageKey = 'WEB3_CONNECT_CACHED_PROVIDER';

const Label: string | undefined = process.env.NEXT_PUBLIC_LABEL || '';
const Token: string | undefined = process.env.NEXT_PUBLIC_TOKEN || '';
const Chain_Id: string | undefined = process.env.NEXT_PUBLIC_CHAIN_ID || '';
const Rpc_Url: string | undefined = process.env.NEXT_PUBLIC_RPC_URL || '';

const AstroNFTContractAddress: string | undefined =
  process.env.NEXT_PUBLIC_ASTRO_NFT_CONTRACTADDRESS || '';
const MarketplaceDatabase: string | undefined =
  process.env.NEXT_PUBLIC_MARKETPLACE_DATABASE || '';
const NFTFETCH: string | undefined = process.env.NEXT_PUBLIC_NFTFETCH || '';
const injected = injectedModule();
const walletLink = walletLinkModule();
const walletConnect = walletConnectModule();
const portis = portisModule({
  apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b',
});
const fortmatic = fortmaticModule({
  apiKey: 'pk_test_886ADCAB855632AA',
});
const torus = torusModule();
const ledger = ledgerModule();
const keepkey = keepkeyModule();
const trezorOptions = {
  email: 'test@test.com',
  appUrl: 'https://www.blocknative.com',
};
const trezor = trezorModule(trezorOptions);
const logo = './CEP-Logo-main-black.png';
export const web3Onboard = init({
  wallets: [
    ledger,
    trezor,
    walletConnect,
    keepkey,
    walletLink,
    injected,
    fortmatic,
    portis,
    torus,
  ],
  chains: [
    {
      id: Chain_Id,
      token: Token,
      label: Label,
      rpcUrl: Rpc_Url,
    },
  ],
  appMetadata: {
    name: 'Cepheus',
    icon: `${logo}`,
    description: 'Node Development And Staking ',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
    ],
  },
  accountCenter: {
    desktop: { enabled: false },
    mobile: { enabled: false },
  },
});

export const WalletContext = createContext<any>({});

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();
  const connectedWallets = useWallets();
  const [address, setAddress] = useState<string>('');

  const [balance, setBalance] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [add, setAdd] = useState('');
  const [FakeBUSDcontract, setFakeBUSDContract] = useState<any>({});
  const [nodeContract, setNodeContract] = useState<any>({});
  const [stakeContract, setStakeContract] = useState<any>({});
  const [tokenContract, setTokenContract] = useState<any>({});
  const [rigelStakeContract, setRigelStakeContract] = useState({});
  const [siriusStakeContract, setSiriusStakeContract] = useState({});
  const [vegaStakeContract, setVegaStakeContract] = useState({});
  const [testEthContract, setTestEthContract] = useState({});

  const [signer, setSigner] = useState<any>({});
  const [provider, setProvider] = useState<any>({});

  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [login, setLogin] = useState<string | undefined>(undefined);
  const [rigelArray, setRigelArray] = useState<any>([]);
  const [siriusArray, setSiriusArray] = useState<any>([]);
  const [vegaArray, setVegaArray] = useState<any>([]);
  const [contracts, setContracts] = useState<any>([]);
  const [nfts, updateNfts] = useState<any>([]);
  const [popUpRigel, setPopUpRigel] = useState<boolean>(false);
  const [popUpSirius, setPopUpSirius] = useState<boolean>(false);
  const [popUpVega, setPopUpVega] = useState<boolean>(false);

  const [ensName, setEnsName] = useState<any>('');
  const [ensAvator, setEnsAvator] = useState<any>('');

  // NFT marketPlace
  const [NFTList, setNFTList] = useState<any[]>([]);
  const [sellType, setSellType] = useState('All');
  const [numPagination, setNumPagination] = useState<any>({
    previous: 0,
    next: 12,
  });

  const [imageSkeleton, setImageSkeleton] = useState(true);
  const [astrolistSkeleton, setAstrolistSkeleton] = useState(true);

  // const [rigelStakedArray, setRigelArray] = useState<any>([]);
  const [isEligible, setIsEligible] = useState<boolean>(true);
  const [isWhiteListed, setisWhiteListed] = useState<boolean>(false);
  const [generalArray, setGeneralArray] = useState<any>({
    TotalRigelNodeSold: 0,
    TotalSiriusNodeSold: 0,
    TotalVegaNodeSold: 0,
    TotalNodeSold: 0,
    TotalRigelNode: 0,
    TotalSiriusNode: 0,
    TotalVegaNode: 0,
    TotalNode: 0,
    TotalUnstakeNodeAdmin: 0,
    RigelStakeTime: 0,
    SiriusStakeTime: 0,
    VegaStakeTime: 0,
    RigelReward: 0,
    SiriusReward: 0,
    VegaReward: 0,
    RigelPrice: 0,
    SiriusPrice: 0,
    VegaPrice: 0,
    AvailableReward: 0,
    RigelStaketime: 0,
    VegaStaketime: 0,
    AvailableRewardFull: 0,
    GlobalStakedRigelNode: 0,
    GlobalStakedSiriusNode: 0,
    GlobalStakedVegaNode: 0,
    GlobalUnStakedRigelNode: 0,
    GlobalUnStakedSiriusNode: 0,
    GlobalUnStakedVegaNode: 0,
    GlobalRigelClaimedReward: 0,
    GlobalSiriusClaimedReward: 0,
    GlobalVegaClaimedReward: 0,
    GlobalStakedNode: 0,
    GlobalUnStakedNode: 0,
    GlobalTotalRigelNode: 0,
    GlobalTotalSiriusNode: 0,
    GlobalTotalVegaNode: 0,
    simpleContractPrice: 0,
    taxableContractPrice: 0,
    deflationaryContractPrice: 0,
    refletionaryContractPrice: 0,
    Erc721ContractPrice: 0,
    Erc1155ContractPrice: 0,
  });
  const [userArray, setUserArray] = useState<any>({
    GlobalStakedNode: 0,
    GlobalUnStakedNode: 0,
    RigelRewardClaimed: 0,
    TotalRewardClaimed: 0,
    SiriusRewardClaimed: 0,
    VegaRewardClaimed: 0,
    RigelStakeNode: 0,
    SiriusStakeNode: 0,
    VegaStakeNode: 0,
    TotalStakeNode: 0,
    UnClaimedRigelReward: 0,
    UnClaimedSiriusReward: 0,
    UnClaimedVegaReward: 0,
    TotalUnclaimedReward: 0,
    RigelUnstakeNode: 0,
    SiriusUnstakeNode: 0,
    VegaUnstakeNode: 0,
    TotalUnstakeNode: 0,
    BusdToken: 0,
    CepheusToken: 0,
    TotalContractData: {},
    rigelStakedArray: [],
    siriusStakedArray: [],
    vegaStakedArray: [],
    totalRigel: '',
    totalSirius: '',
    totalVega: '',
  });
  const [contractArray, setContractArray] = useState([]);
  const [taxcontractArray, setTaxContractArray] = useState([]);
  const [nftcontractArray, setNftContractArray] = useState([]);
  const [nft1155contractArray, setNft1155ContractArray] = useState([]);
  const [walletNFT, setWalletNFT] = useState<any[]>([]);
  const web3Modal = web3Onboard;
  const setCurrentChain = () => setChain({ chainId: Chain_Id });
  const FakeBUSDaddress: string | undefined =
    process.env.NEXT_PUBLIC_FAKEBUSD_ADDRESS || '';
  const Cepheusaddress: string | undefined =
    process.env.NEXT_PUBLIC_CEPHEUSADDRESS || '';
  const Nodeaddress: string | undefined =
    process.env.NEXT_PUBLIC_NODEADDRESS || '';
  const Stakeaddress: string | undefined =
    process.env.NEXT_PUBLIC_STAKEADDRESS || '';
  const RewardAddress: string | undefined =
    process.env.NEXT_PUBLIC_REWARDADDRESS || '';
  const RigelStakeContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_RIGELSTAKE_CONTRACTADDRESS || '';
  const SiriusStakeContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_SIRIUSSTAKE_CONTRACTADDRESS || '';
  const VegaStakeContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_VEGASTAKE_CONTRACTADDRESS || '';
  const PreSaleContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_PRESALE_CONTRACTADDRESS || '';
  const GetAllDataContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_GETALLDATA_CONTRACTADDRESS || '';
  const TokenContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_TOKEN_CONTRACTADDRESS || '';
  const AstroNFTContractAddress: string | undefined =
    process.env.NEXT_PUBLIC_ASTRO_NFT_CONTRACTADDRESS || '';
  const NFTFETCH: string | undefined = process.env.NEXT_PUBLIC_NFTFETCH || '';
  const ALCHEMYKEY: any = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';
  const ASTROCONTRACTADDRESS: string | undefined =
    process.env.NEXT_PUBLIC_ASTRO_CONTRACTADDRESS || '';
  const testEthAddress: string | undefined =
    process.env.NEXT_PUBLIC_TESTETHADDRESS || '';
  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      sessionStorage.getItem('connectedWallets') || '{}'
    );
    if (previouslyConnectedWallets?.length > 0) {
      connect({
        autoSelect: {
          label: previouslyConnectedWallets[0],
          disableModals: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    if (wallet) {
      if (connectedChain) {
        if (connectedChain.id == chains[0].id) {
          walletAddress();
          ownerNFT(address);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, loading1]);

  useEffect(() => {
    if (wallet) {
      if (connectedChain) {
        const value = connectedChain.id;
        if (value !== chains[0].id) {
          try {
            setChain({ chainId: Chain_Id });
          } catch (err) {
            disconnect(wallet);
            setAddress('');
            console.log(err);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connectedChain]);
  useEffect(() => {
    SingerWithoutAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading1]);

  const setWalletAddress = async (provider: any) => {
    try {
      const signer = provider.getSigner();
      if (signer) {
        const web3Address = await signer.getAddress();
        setAddress(web3Address.toLowerCase());
        setAdd(web3Address);
        getBalance(provider, web3Address);
      }
    } catch (error) {
      console.log(
        'Account not connected; logged from setWalletAddress function'
      );
    }
  };

  const getBalance = async (provider: any, walletAddress: string) => {
    const walletBalance = await provider.getBalance(walletAddress);
    const balanceInEth = ethers.utils.formatEther(walletBalance);
    setBalance(balanceInEth);
  };

  const connectToWallet = async () => {
    try {
      setLoading(true);

      setWalletAddress(provider);
    } catch (error) {
      setLoading(false);
      console.log(
        error,
        'got this error on connectToWallet catch block while connecting the wallet'
      );
    }
  };
  const walletAddress = async () => {
    if (wallet) {
      connectedWallets.map(async ({ accounts }) => {
        const address = accounts[0].address;
        setAddress(address);
        const acc = wallet && wallet.accounts[0].address;
        const provider = new EthersWeb3Provider(wallet.provider);
        let ensName = await provider.lookupAddress(address);
        const resolver = ensName ? await provider.getResolver(ensName) : null;
        let avatar = resolver ? await resolver.getAvatar() : null;
        setEnsAvator(avatar);
        setEnsName(ensName);
        setProvider(provider);
        const signer = provider.getSigner(acc);

        // checkOwnerAstroNFT(address, signer);
        setSigner(signer);
        setAdd(acc);

        const con1 = new ethers.Contract(
          FakeBUSDaddress,
          ContractGeneralABI,
          signer
        );
        setFakeBUSDContract(con1);
        const con2 = new ethers.Contract(Cepheusaddress, CepheusABI, signer);
        const con3 = new ethers.Contract(Nodeaddress, NodeABI, signer);
        setNodeContract(con3);

        const con4 = new ethers.Contract(
          PreSaleContractAddress,
          PresaleABI,
          signer
        );
        setRigelStakeContract(con4);
        const con5 = new ethers.Contract(
          PreSaleContractAddress,
          PresaleABI,
          signer
        );
        setSiriusStakeContract(con5);
        const con6 = new ethers.Contract(
          PreSaleContractAddress,
          PresaleABI,
          signer
        );
        setVegaStakeContract(con6);

        const con7 = new ethers.Contract(
          GetAllDataContractAddress,
          GetAllDataABI,
          signer
        );

        const con8 = new ethers.Contract(
          TokenContractAddress,
          TokenContractABI,
          signer
        );
        const rigelC = new ethers.Contract(
          RigelStakeContractAddress,
          cepheusStarABI,
          signer
        );
        const siriusC = new ethers.Contract(
          SiriusStakeContractAddress,
          cepheusStarABI,
          signer
        );
        const vegaC = new ethers.Contract(
          VegaStakeContractAddress,
          cepheusStarABI,
          signer
        );
        setTokenContract(con8);
        getUserDataWhenWalletConnected(
          con3,
          con8,
          con7,
          rigelC,
          siriusC,
          vegaC,
          acc,
          setUserArray,
          setRigelArray,
          setSiriusArray,
          setVegaArray,
          setContractArray,
          setTaxContractArray,
          setNftContractArray,
          setNft1155ContractArray
        );
        Busd(acc, con1, setUserArray);

        // Cepheus(con2, acc, setUserArray);

        setLoading1(false);
      });
    }
  };
  //Fetching NFT of selected account

  const ownerNFT = async (ownerAddress: any) => {
    if (ownerAddress.length > 0) {
      let randomNum = Math.round(Math.random() * 10);
      setWalletNFT([]);
      await axios
        .get(
          `${NFTFETCH}/${
            JSON.parse(ALCHEMYKEY)[randomNum]
          }/getNFTs/?owner=${ownerAddress}`
        )
        .then(async (resp: any) => {
          let data = await Promise.all(
            resp.data.ownedNfts.map(async (val: any) => {
              if (
                val.id.tokenMetadata.tokenType === 'ERC721' &&
                val.metadata.image !== '' &&
                [
                  RigelStakeContractAddress.toLowerCase(),
                  SiriusStakeContractAddress.toLowerCase(),
                  VegaStakeContractAddress.toLowerCase(),
                  ASTROCONTRACTADDRESS.toLowerCase(),
                ].includes(val.contract.address.toLowerCase())
              ) {
                return {
                  name: val.metadata.name,
                  description: val.metadata.description,
                  contract_address: val.contract.address,
                  token_id: val.id.tokenId,
                  image: val.media[0].gateway,
                  category: 'other',
                  nft_type: val.id.tokenMetadata.tokenType,
                  total_amount: val.balance,
                  contentType:
                    ASTROCONTRACTADDRESS.toLowerCase() ==
                    val.contract.address.toLowerCase()
                      ? 'image/png'
                      : 'video/mp4',
                  unique_nft: val.contract.address + val.id.tokenId,
                };
              }
            })
          );
          let arr: any[] = [];
          await Promise.all(
            data.map(async (val: any) => {
              if (val != undefined) {
                arr.push(val);
              }
            })
          );
          setWalletNFT(arr);
        })
        .catch((err: any) => {
          ownerNFT(address);
        });
    }
  };
  // const checkOwnerAstroNFT = async (address: string, signer: any) => {
  //   console.log(address);
  //   const astroContract = new ethers.Contract(
  //     AstroNFTContractAddress,
  //     astroNFTABI,
  //     signer
  //   );
  //   let eligible = await astroContract.isNotEligible(address);
  //   setIsEligible(eligible);
  //   let whiteList = await astroContract.isWhiteListed(address);
  //   setisWhiteListed(whiteList);
  // };

  const SingerWithoutAddress = useCallback(async () => {
    let provider = new ethers.providers.JsonRpcProvider(Rpc_Url);

    const key = ethers.Wallet.createRandom();

    const signer = new Wallet(key, provider);
    let account = await signer.getAddress();
    const EthSigner = new ethers.Wallet(
      '0xc2fd859d63cf04ab1e69d25a6e9a40efd41c85b9fa007344ce3f941007ea23b7',
      provider
    );
    const testEthCon = new ethers.Contract(
      testEthAddress,
      testEhABI,
      EthSigner
    );
    setTestEthContract(testEthCon);
    const acc = account;
    const con1 = new ethers.Contract(FakeBUSDaddress, FakeBUSDABI, signer);
    const con2 = new ethers.Contract(Cepheusaddress, CepheusABI, signer);
    const con3 = new ethers.Contract(Nodeaddress, NodeABI, signer);
    const con4 = new ethers.Contract(
      RigelStakeContractAddress,
      RigelStakingABI,
      signer
    );
    const con5 = new ethers.Contract(
      SiriusStakeContractAddress,
      SiriusStakingABI,
      signer
    );
    const con6 = new ethers.Contract(
      VegaStakeContractAddress,
      VegaStakingABI,
      signer
    );
    const con7 = new ethers.Contract(
      TokenContractAddress,
      TokenContractABI,
      signer
    );
    const con9 = new ethers.Contract(
      PreSaleContractAddress,
      PresaleABI,
      signer
    );
    GeneralNodeData(
      con4,
      con3,
      con7,
      con9,
      setGeneralArray,
      Stakeaddress,
      setUserArray
    );
    // AvailableReward(con2, setGeneralArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading1]);

  return (
    <WalletContext.Provider
      value={{
        address,
        balance,
        loading,
        error,
        connectToWallet,
        // disconnectWallet,
        add,
        setAdd,
        FakeBUSDcontract,
        generalArray,
        userArray,
        setLoading,
        loading1,
        setLoading1,
        nodeContract,
        rigelArray,
        siriusArray,
        vegaArray,
        stakeContract,
        Stakeaddress,
        tokenContract,
        signer,
        contractArray,
        contracts,
        setContracts,
        taxcontractArray,
        setTaxContractArray,
        nfts,
        updateNfts,
        nftcontractArray,
        setNftContractArray,
        nft1155contractArray,
        setNft1155ContractArray,
        connect,
        disconnect,
        web3Onboard,
        wallet,
        setAddress,
        popUpSirius,
        setPopUpSirius,
        popUpVega,
        setPopUpVega,
        popUpRigel,
        setPopUpRigel,
        provider,
        sellType,
        setSellType,
        NFTList,
        setNFTList,
        numPagination,
        setNumPagination,
        rigelStakeContract,
        siriusStakeContract,
        vegaStakeContract,
        testEthContract,
        imageSkeleton,
        setImageSkeleton,
        astrolistSkeleton,
        setAstrolistSkeleton,
        isWhiteListed,
        isEligible,
        // checkOwnerAstroNFT,
        ensName,
        ensAvator,
        ownerNFT,
        walletNFT,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
