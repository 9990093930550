import { ethers } from 'ethers';

export const getUserDataWhenWalletConnected = async (
  con3: any,
  con8: any,
  con7: any,
  rigelC: any,
  siriusC: any,
  vegaC: any,
  acc: any,
  setUserArray: any,
  setRigelArray: any,
  setSiriusArray: any,
  setVegaArray: any,
  setContractArray: any,
  setTaxContractArray: any,
  setNftContractArray: any,
  setNft1155ContractArray: any
) => {
  // const Userdata = await con4.getUserData(acc);

  const Data = await con8.getUserContracts(acc);
  setContractArray(Data[0]);
  setTaxContractArray(Data[1]);
  setNftContractArray(Data[4]);
  setNft1155ContractArray(Data[5]);

  const rigelData = await rigelC.allUserTokens(acc);
  const siriusData = await siriusC.allUserTokens(acc);
  const vegaData = await vegaC.allUserTokens(acc);
  const rigelTotal = rigelData.length;
  const siriusTotal = siriusData.length;
  const vegaTotal = vegaData.length;

  setUserArray((prevUserArray: any) => ({
    ...prevUserArray,

    totalRigel: rigelTotal,
    totalSirius: siriusTotal,
    totalVega: vegaTotal,
  }));
};
export const Busd = async (acc: any, con1: any, setUserArray: any) => {
  const addressvalue = await con1.balanceOf(acc);
  const value = ethers.utils.formatUnits(addressvalue, 6);
  setUserArray((prevUserArray: any) => ({
    ...prevUserArray,
    BusdToken: value,
  }));
};
// export const Cepheus = async (
//   con2: any,
//   acc: number | string,
//   setUserArray: any
// ) => {
//   const addressvalue = await con2.balanceOf(acc);
//   const value = ethers.utils.formatEther(addressvalue);
//   const val5 = parseFloat(value).toFixed(2);
//   setUserArray((prevUserArray: any) => ({
//     ...prevUserArray,
//     CepheusToken: val5,
//   }));
// };
