import { useRouter } from 'next/router';
import { useVideo } from 'react-use';

type CepheusStarProps = {
  image: string;
  name: string;
};
export default function CepheusStar({ image, name }: CepheusStarProps) {
  const router = useRouter();

  const [video, state, controls, ref] = useVideo(
    <video src={image} autoPlay id="videoStar" loop muted />
  );
  return (
    <div
      className={`relative overflow-hidden  rounded-lg bg-white shadow-card transition-all duration-200 hover:shadow-large dark:bg-light-dark `}
      onClick={() => {
        router.push('/DNFTs');
      }}
    >
      {video}
      <div className="px-5 pt-5 pb-3">
        <div className="text-sm font-medium text-black dark:text-white">
          {name}
        </div>
      </div>
    </div>
  );
}
