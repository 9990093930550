import 'swiper/css';
import '@/assets/css/scrollbar.css';
import '@/assets/css/globals.css';
import '@/assets/css/range-slider.css';
import 'react-toastify/dist/ReactToastify.css';

import { Flip, ToastContainer } from 'react-toastify';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { NFTContext, NFTProvider } from '@/lib/hooks/NFT-marketPlace';
import { useContext, useEffect, useState } from 'react';

import type { AppProps } from 'next/app';
import DrawersContainer from '@/components/drawer-views/container';
import Head from 'next/head';
import ModalsContainer from '@/components/modal-views/container';
import type { NextPageWithLayout } from '@/types';
import { ReactQueryDevtools } from 'react-query/devtools';
import SettingsButton from '@/components/settings/settings-button';
import SettingsDrawer from '@/components/settings/settings-drawer';
import { ThemeProvider } from 'next-themes';
import { WalletContext } from '@/lib/hooks/use-connect';
import { WalletProvider } from '@/lib/hooks/use-connect';
import { Web3OnboardProvider } from '@web3-onboard/react';
import { web3Onboard } from '@/lib/hooks/use-connect';

// import { web3Onboard } from '@web3-onboard/react';

// base css file

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  // const  web3Onboard  = Web3OnboardProvider()
  // const web
  const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout ?? ((page) => page);
  useEffect(() => {
    const walletsSub = web3Onboard.state.select('wallets');
    const { unsubscribe } = walletsSub.subscribe(
      (wallets: { label: any }[]) => {
        const connectedWallets = wallets.map(({ label }) => label);
        sessionStorage.setItem(
          'connectedWallets',
          JSON.stringify(connectedWallets)
        );
        const sam = unsubscribe;
      }
    );
    // return unsubscribe;
  }, []);
  //could remove this if you don't need to page level layout
  return (
    <>
      <Head>
        {/* maximum-scale 1 meta tag need to prevent ios input focus auto zooming */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
        <link rel="shortcut icon" href="/cepheuslog.png" />
      </Head>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        theme={'dark'}
        transition={Flip}
      />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider
            attribute="class"
            enableSystem={false}
            defaultTheme="dark"
          >
            <WalletProvider>
              <NFTProvider>
                {getLayout(<Component {...pageProps} />)}
                <SettingsButton />
                <SettingsDrawer />
                <ModalsContainer />
                <DrawersContainer />
              </NFTProvider>
            </WalletProvider>
          </ThemeProvider>
        </Hydrate>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      </QueryClientProvider>
    </>
  );
}

export default CustomApp;
