const routes = {
  home: '/',
  minimal: '/minimal',
  retro: '/retro',
  swap: '/swap',
  liquidity: '/liquidity',
  nodeManagement: '/DNFTs',
  liquidityPosition: '/liquidity-position',
  farms: '/farms',
  farmsTwo: '/farms-2',
  createNft: '/createNft',
  nftDetails: '/nft-details',
  search: '/search',
  ClaimAndUnstake: '/claimAndUnstake',
  notification: '/notification',
  vote: '/vote',
  proposals: '/proposals',
  createProposal: '/proposals/create',
  charts: '/charts',
  profile: '/profile',
  portfolio: '/profile?view=contracts',
  history: '/profile?view=history',
  classic: '/classic',
  staking: '/stake',
  astrolist: '/astrolist',
  tokenContracts: '/tokenContracts',
  stakingContracts: '/stakingContracts',
  nftContracts: '/nftContracts',
  nftContract: '/contractNft',
  nftContract1155: '/nft1155',
  simpleContract: '/simpleContract',
  taxContract: '/taxContract',
  refContract: '/refContract',
  singleToken: '/singleToken',
  multiToken: '/multiToken',
};

export default routes;
