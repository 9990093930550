import Avatar from '@/components/ui/avatar';
import { StaticImageData } from 'next/image';
import { MessageIcon } from '../icons/message';
import ActiveLink from './links/active-link';

type AuthorCardProps = {
  image: any;
  name?: string;
  role?: string;
};

export default function AuthorCard({ image, name, role }: AuthorCardProps) {
  return (
    <div
      className={`flex items-center justify-between rounded-lg  ${
        name
          ? 'bg-gray-100  p-5  dark:bg-light-dark'
          : 'ml-3 justify-center bg-none p-5 dark:mr-3 dark:bg-none'
      }`}
    >
      <div className="flex">
        <Avatar
          image={image}
          alt={name ? name : ''}
          className="dark:border-gray-400"
        />
        <div className="ltr:pl-3 rtl:pr-3">
          <h3 className="text-xs font-medium text-gray-900 dark:text-white">
            {name}
          </h3>
          <span className="mt-1 block text-xs text-gray-600 dark:text-gray-400">
            {role}
          </span>
        </div>
      </div>
      <div>
        <ActiveLink href="/notifications" className="block md:hidden">
          <div className="relative flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-100 bg-white text-brand shadow-main transition-all hover:-translate-y-0.5 hover:shadow-large focus:-translate-y-0.5 focus:shadow-large focus:outline-none dark:border-gray-700 dark:bg-light-dark dark:text-white sm:h-12 sm:w-12">
            <MessageIcon className="h-auto w-3 sm:w-auto" />
            <span className="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-brand shadow-light sm:h-3 sm:w-3" />
          </div>
        </ActiveLink>
      </div>
    </div>
  );
}
