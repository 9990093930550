export function ProfileIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29818 4.00493C3.29818 1.79307 5.09125 0 7.30311 0C9.51498 0 11.308 1.79307 11.308 4.00493C11.308 6.2168 9.51498 8.00987 7.30311 8.00987C5.09125 8.00987 3.29818 6.2168 3.29818 4.00493ZM7.30311 1.41351C5.87191 1.41351 4.71169 2.57373 4.71169 4.00493C4.71169 5.43614 5.87191 6.59636 7.30311 6.59636C8.73432 6.59636 9.89454 5.43614 9.89454 4.00493C9.89454 2.57373 8.73432 1.41351 7.30311 1.41351Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53377 10.8369C2.36278 10.8369 1.41351 11.7862 1.41351 12.9571V14.0769C1.41351 14.094 1.42586 14.1085 1.44269 14.1112C5.32395 14.7449 9.28228 14.7449 13.1635 14.1112C13.1804 14.1085 13.1927 14.094 13.1927 14.0769V12.9571C13.1927 11.7862 12.2435 10.8369 11.0725 10.8369H10.7513C10.7264 10.8369 10.7017 10.8408 10.6781 10.8485L9.86252 11.1148C8.19946 11.6579 6.40677 11.6579 4.74371 11.1148L3.9281 10.8485C3.9045 10.8408 3.87982 10.8369 3.85498 10.8369H3.53377ZM0 12.9571C0 11.0055 1.58212 9.42337 3.53377 9.42337H3.85498C4.02883 9.42337 4.20159 9.45087 4.36686 9.50483L5.18247 9.77115C6.56043 10.2211 8.0458 10.2211 9.42376 9.77115L10.2394 9.50483C10.4046 9.45087 10.5774 9.42337 10.7513 9.42337H11.0725C13.0241 9.42337 14.6062 11.0055 14.6062 12.9571V14.0769C14.6062 14.7867 14.0918 15.3919 13.3913 15.5063C9.3592 16.1646 5.24703 16.1646 1.21493 15.5063C0.514407 15.3919 0 14.7867 0 14.0769V12.9571Z"
        fill="currentColor"
      />
    </svg>
  );
}
