import Image from '@/components/ui/image';
import { useRouter } from 'next/router';
import axios from 'axios';

import { Suspense, useContext, useEffect, useState } from 'react';
import { WalletContext } from '@/lib/hooks/use-connect';
import { useAudio } from 'react-use';

import SkeletonLoading from './skeletonLoading';
import Button from './button/button';
import Link from 'next/link';

type NFTCardAudioProps = {
  id: any;
  name: string;
  image: any;
  category: any;
  contract_address: any;
  sell_symbol: any;
  type_sell: any;
  price: any;
};
export default function NFTCardAudio({
  id,
  name,
  image,
  category,
  contract_address,
  sell_symbol,
  type_sell,
  price,
}: NFTCardAudioProps) {
  const router = useRouter();
  const [condition, setCondition] = useState({ muted: false, play: false });
  const [audio, state, controls, ref] = useAudio({
    src: image,
  });
  const NFTprice = () => {
    let number = price.toString().search('e');
    if (number < 0) {
      return price;
    } else {
      let num1 = parseInt(
        price.toString().charAt(price.toString().search('e') + 2)
      );
      return price.toFixed(
        num1 == 1
          ? 10 +
              parseInt(
                price.toString().charAt(price.toString().search('e') + 3)
              )
          : num1
      );
    }
  };
  return (
    <Link href={`/nft/${id}`}>
      <a
        className={`relative overflow-hidden  rounded-lg bg-white shadow-card transition ease-in-out  hover:shadow-large dark:bg-light-dark `}
      >
        <div
          className={`relative block h-[400px] w-full
          pb-full`}
        >
          <Image
            src={'/audio3.png'}
            layout="fill"
            objectFit="contain"
            alt={name}
          />
          <div className="absolute right-2 bottom-0 z-20 flex">
            {condition.play ? (
              <Button
                shape="circle"
                className="disable-event mx-4"
                onClick={controls.play}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                  />
                </svg>
              </Button>
            ) : (
              <Button
                shape="circle"
                className="disable-event mx-4"
                onClick={controls.play}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
              </Button>
            )}
            {!condition.muted ? (
              <Button
                shape="circle"
                onClick={() => {
                  controls.unmute;
                  setCondition({ ...condition, muted: true });
                }}
                className="disable-event"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
                  />
                </svg>
              </Button>
            ) : (
              <Button
                shape="circle"
                onClick={() => {
                  controls.mute;
                  setCondition({ ...condition, muted: false });
                }}
                className="disable-event"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
                  />
                </svg>
              </Button>
            )}
            {audio}
          </div>
        </div>
        <div className="px-5 pt-5 pb-3">
          <div className="text-sm font-medium text-black dark:text-white">
            {name}
          </div>
          <div className="mt-1 flex justify-between">
            {category == null ? (
              <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
                Other
              </span>
            ) : (
              <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
                {category}
              </span>
            )}
          </div>
          <div className="mt-1 flex justify-between">
            {type_sell == 'null' || type_sell == 'specific_buyer' ? (
              <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
                Make offer
              </span>
            ) : (
              <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
                {type_sell}
              </span>
            )}
            {type_sell == 'null' || type_sell == 'specific_buyer' ? null : (
              <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
                {NFTprice()}
                {sell_symbol}
              </span>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
