export function CompactGridIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48315 1.70279C2.83017 1.62914 2.15366 1.62914 1.50068 1.70279C1.48855 1.70416 1.47655 1.7097 1.46669 1.71951C1.45706 1.7291 1.4533 1.73878 1.45235 1.747C1.37443 2.41934 1.37443 3.09855 1.45235 3.77089C1.4533 3.77911 1.45706 3.7888 1.46669 3.79838C1.47655 3.80819 1.48855 3.81373 1.50068 3.8151C2.15366 3.88876 2.83017 3.88876 3.48315 3.8151C3.49528 3.81373 3.50728 3.80819 3.51714 3.79838C3.52677 3.7888 3.53053 3.77911 3.53149 3.77089C3.6094 3.09855 3.6094 2.41934 3.53149 1.747C3.53053 1.73878 3.52677 1.7291 3.51714 1.71951C3.50728 1.7097 3.49528 1.70416 3.48315 1.70279ZM1.34585 0.304685C2.10173 0.219422 2.8821 0.219422 3.63798 0.304685C4.29993 0.379352 4.83732 0.90497 4.91596 1.58358C5.00646 2.3645 5.00646 3.1534 4.91596 3.93431C4.83732 4.61292 4.29993 5.13854 3.63798 5.21321C2.8821 5.29847 2.10173 5.29847 1.34585 5.21321C0.683906 5.13854 0.146515 4.61292 0.0678732 3.93431C-0.0226244 3.1534 -0.0226244 2.3645 0.0678732 1.58358C0.146515 0.90497 0.683906 0.379352 1.34585 0.304685Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98806 1.70279C9.33508 1.62914 8.65857 1.62914 8.00559 1.70279C7.99346 1.70416 7.98146 1.7097 7.9716 1.71951C7.96197 1.7291 7.95821 1.73878 7.95726 1.747C7.87934 2.41934 7.87934 3.09855 7.95726 3.77089C7.95821 3.77911 7.96197 3.7888 7.9716 3.79838C7.98146 3.80819 7.99346 3.81373 8.00559 3.8151C8.65857 3.88876 9.33508 3.88876 9.98806 3.8151C10.0002 3.81373 10.0122 3.80819 10.0221 3.79838C10.0317 3.7888 10.0354 3.77911 10.0364 3.77089C10.1143 3.09855 10.1143 2.41934 10.0364 1.747C10.0354 1.73878 10.0317 1.7291 10.0221 1.71951C10.0122 1.7097 10.0002 1.70416 9.98806 1.70279ZM7.85076 0.304685C8.60664 0.219422 9.38701 0.219422 10.1429 0.304685C10.8048 0.379352 11.3422 0.90497 11.4209 1.58358C11.5114 2.3645 11.5114 3.1534 11.4209 3.93431C11.3422 4.61292 10.8048 5.13854 10.1429 5.21321C9.38701 5.29847 8.60664 5.29847 7.85076 5.21321C7.18882 5.13854 6.65142 4.61292 6.57278 3.93431C6.48229 3.1534 6.48229 2.3645 6.57278 1.58358C6.65142 0.90497 7.18882 0.379352 7.85076 0.304685Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.493 1.70279C15.84 1.62914 15.1635 1.62914 14.5105 1.70279C14.4984 1.70416 14.4864 1.7097 14.4765 1.71951C14.4669 1.7291 14.4631 1.73878 14.4622 1.747C14.3843 2.41934 14.3843 3.09855 14.4622 3.77089C14.4631 3.77911 14.4669 3.7888 14.4765 3.79838C14.4864 3.80819 14.4984 3.81373 14.5105 3.8151C15.1635 3.88876 15.84 3.88876 16.493 3.8151C16.5051 3.81373 16.5171 3.80819 16.527 3.79838C16.5366 3.7888 16.5404 3.77911 16.5413 3.77089C16.6192 3.09855 16.6192 2.41934 16.5413 1.747C16.5404 1.73878 16.5366 1.7291 16.527 1.71951C16.5171 1.7097 16.5051 1.70416 16.493 1.70279ZM14.3557 0.304685C15.1116 0.219422 15.8919 0.219422 16.6478 0.304685C17.3097 0.379352 17.8471 0.90497 17.9258 1.58358C18.0163 2.3645 18.0163 3.1534 17.9258 3.93431C17.8471 4.61292 17.3097 5.13854 16.6478 5.21321C15.8919 5.29847 15.1116 5.29847 14.3557 5.21321C13.6937 5.13854 13.1563 4.61292 13.0777 3.93431C12.9872 3.1534 12.9872 2.3645 13.0777 1.58358C13.1563 0.90497 13.6937 0.379352 14.3557 0.304685Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48315 8.26792C2.83017 8.19426 2.15366 8.19426 1.50068 8.26792C1.48855 8.26929 1.47655 8.27483 1.46669 8.28464C1.45706 8.29422 1.4533 8.30391 1.45235 8.31213C1.37443 8.98447 1.37443 9.66368 1.45235 10.336C1.4533 10.3442 1.45706 10.3539 1.46669 10.3635C1.47655 10.3733 1.48855 10.3789 1.50068 10.3802C2.15366 10.4539 2.83017 10.4539 3.48315 10.3802C3.49528 10.3789 3.50728 10.3733 3.51714 10.3635C3.52677 10.3539 3.53053 10.3442 3.53149 10.336C3.6094 9.66368 3.6094 8.98447 3.53149 8.31213C3.53053 8.30391 3.52677 8.29422 3.51714 8.28464C3.50728 8.27483 3.49528 8.26929 3.48315 8.26792ZM1.34585 6.86981C2.10173 6.78455 2.8821 6.78455 3.63798 6.86981C4.29993 6.94448 4.83732 7.4701 4.91596 8.14871C5.00646 8.92962 5.00646 9.71852 4.91596 10.4994C4.83732 11.1781 4.29993 11.7037 3.63798 11.7783C2.8821 11.8636 2.10173 11.8636 1.34585 11.7783C0.683906 11.7037 0.146515 11.1781 0.0678732 10.4994C-0.0226244 9.71852 -0.0226244 8.92962 0.0678732 8.14871C0.146515 7.4701 0.683906 6.94448 1.34585 6.86981Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98806 8.26792C9.33508 8.19426 8.65857 8.19426 8.00559 8.26792C7.99346 8.26929 7.98146 8.27483 7.9716 8.28464C7.96197 8.29422 7.95821 8.30391 7.95726 8.31213C7.87934 8.98447 7.87934 9.66368 7.95726 10.336C7.95821 10.3442 7.96197 10.3539 7.9716 10.3635C7.98146 10.3733 7.99346 10.3789 8.00559 10.3802C8.65857 10.4539 9.33508 10.4539 9.98806 10.3802C10.0002 10.3789 10.0122 10.3733 10.0221 10.3635C10.0317 10.3539 10.0354 10.3442 10.0364 10.336C10.1143 9.66368 10.1143 8.98447 10.0364 8.31213C10.0354 8.30391 10.0317 8.29422 10.0221 8.28464C10.0122 8.27483 10.0002 8.26929 9.98806 8.26792ZM7.85076 6.86981C8.60664 6.78455 9.38701 6.78455 10.1429 6.86981C10.8048 6.94448 11.3422 7.4701 11.4209 8.14871C11.5114 8.92962 11.5114 9.71852 11.4209 10.4994C11.3422 11.1781 10.8048 11.7037 10.1429 11.7783C9.38701 11.8636 8.60664 11.8636 7.85076 11.7783C7.18882 11.7037 6.65142 11.1781 6.57278 10.4994C6.48229 9.71852 6.48229 8.92962 6.57278 8.14871C6.65142 7.4701 7.18882 6.94448 7.85076 6.86981Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.493 8.26792C15.84 8.19426 15.1635 8.19426 14.5105 8.26792C14.4984 8.26929 14.4864 8.27483 14.4765 8.28464C14.4669 8.29422 14.4631 8.30391 14.4622 8.31213C14.3843 8.98447 14.3843 9.66368 14.4622 10.336C14.4631 10.3442 14.4669 10.3539 14.4765 10.3635C14.4864 10.3733 14.4984 10.3789 14.5105 10.3802C15.1635 10.4539 15.84 10.4539 16.493 10.3802C16.5051 10.3789 16.5171 10.3733 16.527 10.3635C16.5366 10.3539 16.5404 10.3442 16.5413 10.336C16.6192 9.66368 16.6192 8.98447 16.5413 8.31213C16.5404 8.30391 16.5366 8.29422 16.527 8.28464C16.5171 8.27483 16.5051 8.26929 16.493 8.26792ZM14.3557 6.86981C15.1116 6.78455 15.8919 6.78455 16.6478 6.86981C17.3097 6.94448 17.8471 7.4701 17.9258 8.14871C18.0163 8.92962 18.0163 9.71852 17.9258 10.4994C17.8471 11.1781 17.3097 11.7037 16.6478 11.7783C15.8919 11.8636 15.1116 11.8636 14.3557 11.7783C13.6937 11.7037 13.1563 11.1781 13.0777 10.4994C12.9872 9.71852 12.9872 8.92962 13.0777 8.14871C13.1563 7.4701 13.6937 6.94448 14.3557 6.86981Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48315 14.833C2.83017 14.7594 2.15366 14.7594 1.50068 14.833C1.48855 14.8344 1.47655 14.84 1.46669 14.8498C1.45706 14.8594 1.4533 14.869 1.45235 14.8773C1.37443 15.5496 1.37443 16.2288 1.45235 16.9011C1.4533 16.9094 1.45706 16.9191 1.46669 16.9286C1.47655 16.9384 1.48855 16.944 1.50068 16.9454C2.15366 17.019 2.83017 17.019 3.48315 16.9454C3.49528 16.944 3.50728 16.9384 3.51714 16.9286C3.52677 16.9191 3.53053 16.9094 3.53149 16.9011C3.6094 16.2288 3.6094 15.5496 3.53149 14.8773C3.53053 14.869 3.52677 14.8594 3.51714 14.8498C3.50728 14.84 3.49528 14.8344 3.48315 14.833ZM1.34585 13.4349C2.10173 13.3497 2.8821 13.3497 3.63798 13.4349C4.29993 13.5096 4.83732 14.0352 4.91596 14.7138C5.00646 15.4948 5.00646 16.2837 4.91596 17.0646C4.83732 17.7432 4.29993 18.2688 3.63798 18.3435C2.8821 18.4287 2.10173 18.4287 1.34585 18.3435C0.683906 18.2688 0.146515 17.7432 0.0678732 17.0646C-0.0226244 16.2837 -0.0226244 15.4948 0.0678732 14.7138C0.146515 14.0352 0.683906 13.5096 1.34585 13.4349Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98806 14.833C9.33508 14.7594 8.65857 14.7594 8.00559 14.833C7.99346 14.8344 7.98146 14.84 7.9716 14.8498C7.96197 14.8594 7.95821 14.869 7.95726 14.8773C7.87934 15.5496 7.87934 16.2288 7.95726 16.9011C7.95821 16.9094 7.96197 16.9191 7.9716 16.9286C7.98146 16.9384 7.99346 16.944 8.00559 16.9454C8.65857 17.019 9.33508 17.019 9.98806 16.9454C10.0002 16.944 10.0122 16.9384 10.0221 16.9286C10.0317 16.9191 10.0354 16.9094 10.0364 16.9011C10.1143 16.2288 10.1143 15.5496 10.0364 14.8773C10.0354 14.869 10.0317 14.8594 10.0221 14.8498C10.0122 14.84 10.0002 14.8344 9.98806 14.833ZM7.85076 13.4349C8.60664 13.3497 9.38701 13.3497 10.1429 13.4349C10.8048 13.5096 11.3422 14.0352 11.4209 14.7138C11.5114 15.4948 11.5114 16.2837 11.4209 17.0646C11.3422 17.7432 10.8048 18.2688 10.1429 18.3435C9.38701 18.4287 8.60664 18.4287 7.85076 18.3435C7.18882 18.2688 6.65142 17.7432 6.57278 17.0646C6.48229 16.2837 6.48229 15.4948 6.57278 14.7138C6.65142 14.0352 7.18882 13.5096 7.85076 13.4349Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.493 14.833C15.84 14.7594 15.1635 14.7594 14.5105 14.833C14.4984 14.8344 14.4864 14.84 14.4765 14.8498C14.4669 14.8594 14.4631 14.869 14.4622 14.8773C14.3843 15.5496 14.3843 16.2288 14.4622 16.9011C14.4631 16.9094 14.4669 16.9191 14.4765 16.9286C14.4864 16.9384 14.4984 16.944 14.5105 16.9454C15.1635 17.019 15.84 17.019 16.493 16.9454C16.5051 16.944 16.5171 16.9384 16.527 16.9286C16.5366 16.9191 16.5404 16.9094 16.5413 16.9011C16.6192 16.2288 16.6192 15.5496 16.5413 14.8773C16.5404 14.869 16.5366 14.8594 16.527 14.8498C16.5171 14.84 16.5051 14.8344 16.493 14.833ZM14.3557 13.4349C15.1116 13.3497 15.8919 13.3497 16.6478 13.4349C17.3097 13.5096 17.8471 14.0352 17.9258 14.7138C18.0163 15.4948 18.0163 16.2837 17.9258 17.0646C17.8471 17.7432 17.3097 18.2688 16.6478 18.3435C15.8919 18.4287 15.1116 18.4287 14.3557 18.3435C13.6937 18.2688 13.1563 17.7432 13.0777 17.0646C12.9872 16.2837 12.9872 15.4948 13.0777 14.7138C13.1563 14.0352 13.6937 13.5096 14.3557 13.4349Z"
        fill="currentColor"
      />
    </svg>
  );
}
