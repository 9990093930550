export function PoolIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.42352 4.76612C5.55462 4.89722 5.73242 4.97087 5.91781 4.97087C6.10321 4.97087 6.28101 4.89722 6.4121 4.76612L7.34414 3.83408C7.54407 3.63416 7.60387 3.3335 7.49568 3.07229C7.38748 2.81108 7.13259 2.64076 6.84986 2.64076H4.98577C4.70304 2.64076 4.44815 2.81108 4.33995 3.07229C4.23176 3.3335 4.29156 3.63416 4.49148 3.83408L5.42352 4.76612Z"
        fill="currentColor"
      />
      <path
        d="M5.42352 11.2339C5.55462 11.1028 5.73242 11.0291 5.91781 11.0291C6.10321 11.0291 6.28101 11.1028 6.4121 11.2339L7.34414 12.1659C7.54407 12.3658 7.60387 12.6665 7.49568 12.9277C7.38748 13.1889 7.13259 13.3592 6.84986 13.3592H4.98577C4.70304 13.3592 4.44815 13.1889 4.33995 12.9277C4.23176 12.6665 4.29156 12.3658 4.49148 12.1659L5.42352 11.2339Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98876 8L7.16332 7.84959C9.00451 6.26325 10.5353 4.349 11.678 2.20408C12.1264 1.36238 11.5697 0.336125 10.6196 0.253059L9.52586 0.157427C7.12508 -0.052476 4.71056 -0.0524757 2.30979 0.157428L1.216 0.25306C0.265927 0.336127 -0.290782 1.36238 0.15763 2.20408C1.30033 4.349 2.83114 6.26325 4.67232 7.84959L4.84689 8L4.67232 8.15041C2.83114 9.73675 1.30033 11.651 0.15763 13.7959C-0.290782 14.6376 0.265927 15.6639 1.216 15.7469L2.30979 15.8426C4.71056 16.0525 7.12508 16.0525 9.52586 15.8426L10.6196 15.7469C11.5697 15.6639 12.1264 14.6376 11.678 13.7959C10.5353 11.651 9.00451 9.73675 7.16332 8.15041L6.98876 8ZM5.92122 7.0738C5.92164 7.07364 5.92241 7.07335 5.92387 7.07208L6.25077 6.79044C7.93507 5.33926 9.33974 3.59265 10.3958 1.63688L9.40409 1.55018C7.08434 1.34736 4.75131 1.34736 2.43156 1.55018L1.43987 1.63688C2.49591 3.59265 3.90058 5.33926 5.58488 6.79044L5.91177 7.07208C5.91271 7.07289 5.91336 7.0733 5.91382 7.07354C5.91408 7.07367 5.91367 7.07348 5.91382 7.07354C5.91453 7.07379 5.91631 7.07433 5.91782 7.07433C5.91934 7.07433 5.92051 7.07406 5.92122 7.0738ZM5.92387 8.92792C5.92241 8.92665 5.92164 8.92636 5.92122 8.9262C5.92088 8.92608 5.92044 8.92595 5.9199 8.92585C5.91932 8.92574 5.91862 8.92567 5.91782 8.92567C5.91631 8.92567 5.91514 8.92594 5.91443 8.9262C5.91401 8.92636 5.91324 8.92665 5.91177 8.92792L5.58488 9.20956C3.90058 10.6607 2.49591 12.4073 1.43987 14.3631L2.43156 14.4498C4.75131 14.6526 7.08434 14.6526 9.40409 14.4498L10.3958 14.3631C9.33974 12.4074 7.93507 10.6607 6.25077 9.20956L5.92387 8.92792Z"
        fill="currentColor"
      />
    </svg>
  );
}
