import NFTCardImage from './NFTCardImage';
import NFTCardVideo from './NFTCardVideo';
import NFTCardAudio from './NFTCardAudio';
import { useRouter } from 'next/router';

type NFTGridProps = {
  key: any;
  id: any;
  name: string;
  image: any;
  category: any;
  contract_address: any;
  sell_symbol: any;
  type_sell: any;
  price: any;
  image_content_type: string;
};
export default function NFTGrid({
  key,
  id,
  name,
  image,
  category,
  contract_address,
  sell_symbol,
  type_sell,
  price,
  image_content_type,
}: NFTGridProps) {
  const router = useRouter();
  return (
    <div
      className={`relative overflow-hidden  rounded-lg bg-white shadow-card transition delay-150 ease-in-out  hover:shadow-large dark:bg-light-dark `}
      onClick={() => {
        router.push(`/nft/${id}`);
      }}
    >
      {[
        'image/gif',
        'image/apng',
        'image/avif',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/webp',
      ].includes(image_content_type) ? (
        <>
          <NFTCardImage
            id={id}
            name={name}
            image={image}
            category={category}
            contract_address={contract_address}
            sell_symbol={sell_symbol}
            type_sell={type_sell}
            price={price}
          />
        </>
      ) : ['video/mp4', 'video/ogg', 'video/webm', 'video/quicktime'].includes(
          image_content_type
        ) ? (
        <NFTCardVideo
          id={id}
          name={name}
          image={image}
          category={category}
          contract_address={contract_address}
          image_content_type={image_content_type}
          sell_symbol={sell_symbol}
          type_sell={type_sell}
          price={price}
        />
      ) : [
          'audio/mpeg',
          'audio/x-ms-wma',
          'audio/vnd.rn-realaudio',
          'audio/x-wav',
        ].includes(image_content_type) ? (
        <NFTCardAudio
          id={id}
          name={name}
          image={image}
          category={category}
          contract_address={contract_address}
          sell_symbol={sell_symbol}
          type_sell={type_sell}
          price={price}
        />
      ) : null}
    </div>
  );
}
