import axios from 'axios';
import { values } from 'lodash';
import {
  useEffect,
  useState,
  createContext,
  ReactNode,
  useCallback,
  useReducer,
} from 'react';
const MarketplaceDatabase: string | undefined =
  process.env.NEXT_PUBLIC_MARKETPLACE_DATABASE || '';
const MARKETPLACEDATABASEPAGINATION: string | undefined =
  process.env.NEXT_PUBLIC_MARKETPLACE_DATABASE_PAGINATION || '';
export const NFTContext = createContext<any>({});
export const NFTProvider = ({ children }: { children: ReactNode }) => {
  const [pagination, setPagination] = useState({
    allNFTpage: 1,
    sellFixedPage: 1,
    sellAuctionPage: 1,
    categorypage: 1,
  });
  const [NFTType, setNFTType] = useState('All');
  const [hasMore, setHasMore] = useState(false);
  const [NFTList, setNFTList] = useState<any[]>([]);
  const [sellModal, setSellModal] = useState({
    makeOffer: false,
    buyNow: false,
    auction: false,
    sell: false,
    specificBuyer: false,
    ownerList: false,
  });
  const [buttonLoading, setButtonLoading] = useState({
    makeOffer: false,
    sell: false,
    cancel: false,
    bid: false,
    fixed: false,
    specificBuyer: false,
    acceptMake: false,
    acceptBid: false,
    astroclaim: false,
    astroBuy: false,
  });

  const [swapFrom, setSwapFrom] = useState({ name: '', symbol: '' });
  const [swapTo, setSwapTo] = useState({ name: '', symbol: '' });
  const fetchData = async () => {
    if (NFTType == 'All') {
      await axios
        .get(
          `${MARKETPLACEDATABASEPAGINATION}nfts?page=${pagination.allNFTpage}`
        )
        .then((val) => {
          setHasMore(val.data.length < 12 ? false : true);
          if (pagination.allNFTpage == 1) {
            setNFTList(val.data);
            setPagination({
              ...pagination,
              allNFTpage: pagination.allNFTpage + 1,
              sellFixedPage: 1,
              categorypage: 1,
              sellAuctionPage: 1,
            });
          } else {
            setNFTList([...NFTList, ...val.data]);
            setPagination({
              ...pagination,
              allNFTpage: pagination.allNFTpage + 1,
            });
          }
        })
        .catch((err) => {});
    } else if (NFTType == 'fixed') {
      await axios
        .get(
          `${MarketplaceDatabase}nfts?type_sell=${NFTType}&&page=${pagination.sellFixedPage}`
        )
        .then((val) => {
          setHasMore(val.data.length < 12 ? false : true);
          if (pagination.sellFixedPage == 1) {
            setNFTList(val.data);
            setPagination({
              ...pagination,
              allNFTpage: 1,
              sellFixedPage: pagination.sellFixedPage + 1,
              categorypage: 1,
              sellAuctionPage: 1,
            });
          } else {
            setNFTList([...NFTList, ...val.data]);
            setPagination({
              ...pagination,
              sellFixedPage: pagination.sellFixedPage + 1,
            });
          }
        });
    } else if (NFTType == 'auction') {
      await axios
        .get(
          `${MarketplaceDatabase}nfts?type_sell=${NFTType}&&page=${pagination.sellAuctionPage}`
        )
        .then((val) => {
          setHasMore(val.data.length < 12 ? false : true);
          if (pagination.sellAuctionPage == 1) {
            setNFTList(val.data);
            setPagination({
              ...pagination,
              allNFTpage: 1,
              sellAuctionPage: pagination.sellAuctionPage + 1,
              categorypage: 1,
              sellFixedPage: 1,
            });
          } else {
            setNFTList([...NFTList, ...val.data]);
            setPagination({
              ...pagination,
              sellAuctionPage: pagination.sellAuctionPage + 1,
            });
          }
        });
    } else {
      await axios
        .get(
          `${MarketplaceDatabase}nfts?category=${NFTType}&&page=${pagination.categorypage}`
        )
        .then((val) => {
          setHasMore(val.data.length < 12 ? false : true);
          if (pagination.allNFTpage == 1) {
            setNFTList(val.data);
            setPagination({
              ...pagination,
              categorypage: pagination.categorypage + 1,
              sellFixedPage: 1,
              allNFTpage: 1,
              sellAuctionPage: 1,
            });
          } else {
            setNFTList([...NFTList, ...val.data]);
            setPagination({
              ...pagination,
              categorypage: pagination.categorypage + 1,
            });
          }
        })
        .catch((err) => {});
    }
  };
  return (
    <NFTContext.Provider
      value={{
        NFTType,
        hasMore,
        setNFTType,
        fetchData,
        NFTList,
        setNFTList,
        pagination,
        setPagination,
        sellModal,
        setSellModal,
        swapFrom,
        setSwapFrom,
        swapTo,
        setSwapTo,
        buttonLoading,
        setButtonLoading,
      }}
    >
      {children}
    </NFTContext.Provider>
  );
};
