import { useRouter } from 'next/router';
type NFTCardVideoProps = {
  id: any;
  name: string;
  image: any;
  category: any;
  contract_address: any;
  sell_symbol: any;
  type_sell: any;
  image_content_type: string;
  price: any;
};
export default function NFTCardVideo({
  id,
  name,
  image,
  category,
  contract_address,
  sell_symbol,
  type_sell,
  image_content_type,
  price,
}: NFTCardVideoProps) {
  const router = useRouter();
  // const [video, state, controls, ref] = useVideo(
  //   <video src={image} autoPlay loop muted  />
  // );

  const NFTprice = () => {
    let number = price.toString().search('e');
    if (number < 0) {
      return price;
    } else {
      let num1 = parseInt(
        price.toString().charAt(price.toString().search('e') + 2)
      );
      return price.toFixed(
        num1 == 1
          ? 10 +
              parseInt(
                price.toString().charAt(price.toString().search('e') + 3)
              )
          : num1
      );
    }
  };
  return (
    <div
      className={`relative overflow-hidden  rounded-lg bg-white shadow-card transition ease-in-out  hover:shadow-large dark:bg-light-dark `}
      onClick={() => {
        router.push(`/nft/${id}`);
      }}
    >
      <div className="block h-[400px] w-full items-center bg-black pb-full">
        <video className="h-[400px] w-full " controls muted preload="none">
          <source src={image} type={image_content_type} />
        </video>
      </div>
      <div className="px-5 pt-5 pb-3">
        <div className="text-sm font-medium text-black dark:text-white">
          {name}
        </div>
        <div className="mt-1 flex justify-between">
          {category == null ? (
            <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
              Other
            </span>
          ) : (
            <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
              {category}
            </span>
          )}
        </div>
        <div className="mt-1 flex justify-between">
          {type_sell == 'null' || type_sell == 'specific_buyer' ? (
            <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
              Make offer
            </span>
          ) : (
            <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
              {type_sell}
            </span>
          )}
          {type_sell == 'null' || type_sell == 'specific_buyer' ? null : (
            <span className="inline-flex h-full shrink-0 grow-0 items-center rounded-full  py-1 text-xs font-medium capitalize text-blue-600  sm:text-sm">
              {NFTprice()}
              {sell_symbol}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
